<template>
  <b-modal
    id="create-or-update-lesson-modal"
    v-model="$show"
    centered
    scrollable
    size="xl"
    header-class="modal__header"
    footer-class="modal__footer"
    body-class="modal__body"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <template #modal-header="{}">
      <h3 class="font-weight-bold">เพิ่ม/แก้ไขข้อมูล{{ title }}</h3>
      <span v-if="updatedAt">อัพเดตล่าสุด: {{ updatedAt }}</span>
    </template>

    <div class="col-12 px-2">
      <b-form-row>
        <div
          v-if="!hiddenInput.lessonTitle"
          class="col-12"
          :class="{ [`col-lg-${colLessonTitle}`]: true }"
        >
          <label class="font-weight-bold mb-2" for="lessonTitle"
            >ชื่อ{{ title }}</label
          >

          <b-form-input
            id="lessonTitle"
            type="text"
            :state="invalid ? $v.lessonTitle.required : null"
            v-model="lessonTitle"
          ></b-form-input>

          <b-form-invalid-feedback id="lessonTitle-feedback">
            กรุณากรอกชื่อ{{ title }}
          </b-form-invalid-feedback>
        </div>

        <div
          v-if="!hiddenInput.lessonNo"
          class="col-6"
          :class="{ [`col-lg-${colLessonNo}`]: true }"
        >
          <label class="font-weight-bold mb-2" for="lessonNo"
            >{{ sectionName }}ที่</label
          >

          <b-form-input
            id="lessonNo"
            type="number"
            :min="1"
            :state="invalid ? $v.lessonNo.required : null"
            v-model.number="lessonNo"
          ></b-form-input>

          <b-form-invalid-feedback id="lessonNo-feedback">
            กรุณากรอก{{ sectionName }}ที่
          </b-form-invalid-feedback>
        </div>

        <div
          v-if="!hiddenInput.lessonOrder"
          class="col-6"
          :class="{ [`col-lg-${colLessonOrder}`]: true }"
        >
          <label class="font-weight-bold mb-2" for="lessonOrder"
            >ลำดับที่</label
          >

          <b-form-input
            id="lessonOrder"
            type="number"
            :min="1"
            :state="invalid ? $v.lessonOrder.required : null"
            v-model="lessonOrder"
          ></b-form-input>

          <b-form-invalid-feedback id="lessonOrder-feedback">
            กรุณากรอกลำดับที่
          </b-form-invalid-feedback>
        </div>

        <div
          v-if="!hiddenInput.lessonCoverTime"
          class="col-6"
          :class="{ [`col-lg-${colLessonCoverTime}`]: true }"
        >
          <label class="font-weight-bold mb-2" for="lessonCoverTime"
            >Preview (วินาที)</label
          >

          <b-form-input
            id="lessonCoverTime"
            type="number"
            :min="0"
            v-model="lessonCoverTime"
          ></b-form-input>
        </div>
      </b-form-row>
    </div>

    <b-embed
      v-if="lessonUrl"
      :key="lessonSrc"
      type="video"
      controls
      muted
      class="mt-3 p-2"
      preload="auto"
      :poster="`https://drive.google.com/thumbnail?id=${getThumbnail(googleDriveUrl)}`"
    >
      <source :src="lessonSrc" type="video/mp4" />
    </b-embed>

    <!-- <b-embed v-if="googleDriveSrc" type="video" controls class="mt-3 p-2">
      <source :src="googleDriveSrc" type="video/mp4" />
    </b-embed> -->

    <!-- <b-embed
      v-if="youtubeSrc"
      :src="youtubeSrc"
      class="mt-3 p-2"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    >
    </b-embed> -->

    <div v-if="lessonUrl" class="col-12 mb-5" style="text-align: center">
      <b-button
        variant="danger"
        size="sm"
        class="mt-3"
        @click="clearSrc"
        :disabled="!lessonUrl"
        >ลบเพื่ออัพโหลดใหม่</b-button
      >
    </div>

    <!-- <div class="col-12 px-2 mt-4">
      <label class="font-weight-bold">อัพโหลดวีดีโอจากยูทูป</label>
      <small class="text-black-50 ml-2 font-weight-bold"
        >ตัวอย่าง: https://www.youtube.com/watch?v=****</small
      >
    </div>

    <div class="d-flex flex-row flex-wrap px-2 my-4 justify-content-between">
      <b-form-input
        id="stationName"
        type="text"
        class="col-7 col-md-10"
        :disabled="lessonUrl !== '' || googleDriveSrc !== ''"
        v-model="youTubeUrl"
        placeholder="วางลิงก์ YouTube ที่นี่"
      ></b-form-input>

      <span class="col-5 col-md-2">
        <b-button
          size="sm"
          variant="primary"
          :disabled="lessonUrl !== '' || googleDriveSrc !== ''"
          @click="validateYouTubeUrl"
        >
          ตรวจสอบลิงก์
        </b-button>
      </span>
    </div>

    <div class="separator">หรือ</div> -->

    <div class="col-12 px-2 mt-4">
      <label class="font-weight-bold">อัพโหลดจาก Google Drive</label>
      <small class="text-black-50 ml-2 font-weight-bold"
        >ตัวอย่างลิงค์: https://drive.google.com/file/d/****/view?usp=sharing
        (ไฟล์ขนาดไม่ควรเกิน 100 MB)</small
      >
    </div>

    <div class="d-flex flex-row flex-wrap px-2 my-4 justify-content-between">
      <b-form-group class="col-7 col-md-10 px-0">
        <b-form-input
          id="googleDriveUrl"
          type="text"
          :state="invalidLessonUrl ? invalidLessonUrlFlag : null"
          :disabled="lessonUrl.length > 0"
          v-model="googleDriveUrl"
          placeholder="วางลิงก์ Google Drive ที่นี่"
        ></b-form-input>

        <b-form-invalid-feedback id="lessonOrder-feedback">
          กรุณากรอกลิงก์ Google Drive ที่ถูกต้อง
        </b-form-invalid-feedback>
      </b-form-group>

      <span class="col-5 col-md-2 text-right pr-0">
        <b-button
          size="sm"
          variant="primary"
          block
          :disabled="lessonUrl.length > 0"
          @click="validateGoogleDriveUrl"
        >
          ตรวจสอบลิงก์
        </b-button>
      </span>
    </div>

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="cancel()"
      >
        ยกเลิก
      </b-button>

      <b-button
        size="sm"
        variant="primary"
        @click.prevent="save"
        :disabled="invalidLessonUrl"
      >
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else>บันทึก</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import formatDateMixin from "../../mixins/formatDateMixin";
import validatorMixin from "../../mixins/validatorMixin";
import { Auth, Lesson } from "../../models";

export default {
  mixins: [formatDateMixin, validatorMixin, validationMixin],

  components: {},

  props: {
    show: Boolean,

    type: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      default: "บทเรียน",
    },

    sectionName: {
      type: String,
      default: "บท",
    },

    editData: {
      type: Object,
      default: () => ({}),
    },

    hiddenInput: {
      type: Object,
      default: () => ({}),
    },

    colLessonTitle: {
      type: Number,
      default: 6,
    },

    colLessonNo: {
      type: Number,
      default: 2,
    },

    colLessonOrder: {
      type: Number,
      default: 2,
    },

    colLessonCoverTime: {
      type: Number,
      default: 2,
    },
  },

  model: {
    prop: "show",
    event: "update",
  },

  watch: {
    editData: {
      immediate: true,
      handler: "cloneEditData",
    },
  },

  data() {
    return {
      isSubmiting: false,
      invalid: false,
      invalidLessonUrl: false,
      invalidLessonUrlFlag: false,
      // youTubeUrl: "",
      googleDriveUrl: "",
      // youtubeSrc: "",
      // googleDriveSrc: "",
      lessonTitle: "",
      lessonUrl: "",
      lessonWatchUrl: "",
      lessonNo: 1,
      lessonOrder: 1,
      lessonCoverTime: 0,
      updatedAt: "",
      // disabled: false,
    };
  },

  validations: {
    lessonTitle: { required },
    lessonNo: { required },
    lessonOrder: { required },
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },

    lessonSrc() {
      return `${this.lessonUrl}#t=${this.lessonCoverTime}`;
    },
  },

  methods: {
    cloneEditData(v) {
      if (v && v.id) {
        this.lessonTitle = v.lesson_title;
        this.lessonUrl = v.lesson_url;
        this.googleDriveUrl = v.lesson_watch_url;
        this.lessonWatchUrl = v.lesson_watch_url;
        this.lessonNo = v.lesson_no;
        this.lessonOrder = v.lesson_order;
        this.lessonCoverTime = v.lesson_cover_time;
        this.updatedAt = v.updatedAt;
      }

      // if (this.validateYouTubeUrlforEdit(v.keyVdo)) {
      //   this.youtubeSrc = v.keyVdo;
      // } else
    },

    // onClickUploadVideo() {
    //   this.$nextTick(() => {
    //     this.$refs.upload__video.click();
    //   });
    // },

    // previewVideo() {
    //   this.lessonUrl = "";
    //   const self = this;
    //   let reader = new FileReader();
    //   reader.readAsDataURL(this.form.file);
    //   reader.addEventListener("load", function () {
    //     self.lessonUrl = reader.result;
    //   });
    // },

    // handleFileUpload(event) {
    //   const file = event.target.files[0];
    //   if (!file) return;
    //   this.$set(this.form, "file", file);
    //   this.previewVideo();
    //   file.value = "";
    // },

    async save() {
      this.$v.$touch();

      this.invalid = false;

      if (this.$v.$invalid) {
        this.invalid = true;

        return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      }

      let promise;

      try {
        if (!this.lessonUrl || !this.lessonWatchUrl) {
          return this.$toast.error(`กรุณากรอกลิ้งค์${this.title}`);
        }

        this.isSubmiting = true;

        let formData = {
          lesson_title: this.lessonTitle,
          lesson_url: this.lessonUrl,
          lesson_watch_url: this.lessonWatchUrl,
          lesson_no: this.lessonNo,
          lesson_order: this.lessonOrder,
          lesson_cover_time: this.lessonCoverTime,
        };

        const { id } = this.editData;

        if (id) {
          promise = await Lesson.api().update(id, formData);

          const { data } = promise.response;

          if (data && data.response_status == "SUCCESS") {
            this.$toast.success(`อัพเดต${this.title}เรียบร้อยแล้ว`);

            this.$bvModal.hide("create-or-update-lesson-modal");

            this.$emit("update:success");
          } else {
            this.$toast.error(
              `อัพเดต${this.title}ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
            );
          }
        } else {
          promise = await Lesson.api().create({
            ...formData,
            lesson_type: this.type,
          });

          const { data } = promise.response;

          if (data && data.response_status == "SUCCESS") {
            this.$toast.success(`สร้าง${this.title}เรียบร้อยแล้ว`);

            this.$bvModal.hide("create-or-update-lesson-modal");

            this.$emit("create:success");
          } else {
            this.$toast.error(
              `สร้าง${this.title}ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
            );
          }
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(error);
      } finally {
        this.isSubmiting = false;
      }
    },

    // validateYouTubeUrlforEdit(src = "") {
    //   if (src != undefined || src != "") {
    //     var regExp =
    //       /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    //     if (src.match(regExp)) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // },

    // validateGoogleDriveUrlforEdit(src = "") {
    //   if (src != undefined || src != "") {
    //     const regExp =
    //       /https:\/\/drive\.google\.com\/uc\?export=download&id=(.*?)/g;

    //     if (src.match(regExp)) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // },

    // validateYouTubeUrl() {
    //   this.youtubeSrc = "";

    //   if (this.youTubeUrl != undefined || this.youTubeUrl != "") {
    //     var regExp =
    //       /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    //     if (this.youTubeUrl.match(regExp)) {
    //       this.youtubeSrc = this.youTubeUrl.replace("watch?v=", "embed/");
    //     } else {
    //       this.$toast.error(`ลิงก์ยูทูปไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง`);
    //     }
    //   }
    // },

    validateGoogleDriveUrl() {
      if (this.googleDriveUrl != undefined || this.googleDriveUrl != "") {
        this.invalidLessonUrl = false;
        this.invalidLessonUrlFlag = true;

        const regExp =
          /https:\/\/drive\.google\.com\/file\/d\/(.*?)\/.*?\?usp=sharing/g;

        const matchResult = this.googleDriveUrl.match(regExp);

        if (matchResult) {
          const matchUrl = matchResult[0];

          this.lessonWatchUrl = matchUrl;

          this.lessonUrl = matchUrl.replace(
            regExp,
            "https://drive.google.com/uc?export=download&id=$1"
          );
        } else {
          this.invalidLessonUrl = true;
          this.invalidLessonUrlFlag = false;

          this.$toast.error(
            `ลิงก์ Google Drive ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง`
          );
        }
        // https://drive.google.com/file/d/10ChyFfWb0vH5b7JslIe12OU5EWlVlabJ/view?usp=sharing
        // https://drive.google.com/uc?export=download&id=10ChyFfWb0vH5b7JslIe12OU5EWlVlabJ
      }
    },

    getThumbnail(driveUrl) {
      let urlId = driveUrl
        .split("https://drive.google.com/file/d/")[1]
        .split("/view?usp=sharing")[0];

      return urlId;
    },

    onHidden() {
      this.clearData();

      this.$emit("onHide");
    },

    clearSrc() {
      // this.youTubeUrl = "";
      // this.youtubeSrc = "";
      this.invalidLessonUrl = false;
      this.invalidLessonUrlFlag = true;
      this.lessonUrl = "";
      this.lessonWatchUrl = "";
      this.googleDriveUrl = "";
    },

    clearData() {
      this.clearSrc();

      this.invalid = false;
      this.lessonTitle = "";
      this.lessonNo = 1;
      this.lessonOrder = 1;
      this.lessonCoverTime = 0;
      this.updatedAt = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #d1d2db;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}
</style>
